import React, { useState, useEffect } from 'react'

const PasswordStrengthBar = ({ password, setComplete }) => {
    const [barPercentage, setBarPercentage] = useState('0%');
    const [barColor, setBarColor] = useState('bg-red-500');
    const [validLength, setValidLength] = useState(false);
    const [validCase, setValidCase] = useState(false);
    const [validNumber, setValidNumber] = useState(false);
    const [validSymbol, setValidSymbol] = useState(false);

    useEffect(() => {
        if (!password) {
            return;
        }
        let currentPercentage = 0;
        if (password.length > 7) {
            currentPercentage += 1/4;
            setValidLength(true);
        } else {
            setValidLength(false);
        }

        if (password.toLowerCase() !== password && password.toUpperCase() !== password) {
            currentPercentage += 1/4;
            setValidCase(true);
        } else {
            setValidCase(false);
        }

        if (/\d/.test(password)) {
            currentPercentage += 1/4;
            setValidNumber(true);
        } else {
            setValidNumber(false);
        }

        if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)) {
            currentPercentage += 1/4;
            setValidSymbol(true);
        } else {
            setValidSymbol(false);
        }

        if (currentPercentage === 1) {
            setComplete(true);
        }
        setBarPercentage(`${currentPercentage * 100}%`);
        setBarColor(currentPercentage === 1 ? 'bg-green-500' : currentPercentage === 2/4 ? 'bg-orange-500' : currentPercentage === 3/4 ? 'bg-yellow-500' : 'bg-red-500');
    } , [password])

    return (
        <div className='flex flex-col py-1 gap-1 text-sm'>
            <div className="w-full h-3 bg-gray-200 rounded-full">
                <div className={`h-full rounded-full ${barColor} transition-all`} style={{ width: `${barPercentage}` }}></div>
            </div>
            <div className='w-full flex justify-between'>
                <span>
                    Weak
                </span>
                <span>
                    Strong
                </span>
            </div>
            <div className='flex flex-col text-sm'>
                <div className={`${validLength ? 'text-normative-blue' : 'text-text-secondary-light'}`}>✓ Password must be at least 8 characters</div>
                <div className={`${validCase ? 'text-normative-blue' : 'text-text-secondary-light'}`}>✓ Password must contain uppercase and lowercase characters</div>
                <div className={`${validNumber ? 'text-normative-blue' : 'text-text-secondary-light'}`}>✓ Password must contain a number</div>
                <div className={`${validSymbol ? 'text-normative-blue' : 'text-text-secondary-light'}`}>✓ Password must contain a symbol</div>
            </div>
            {/* <div className="text-xs text-gray-600 flex justify-end">{password.length < 8 ? 'Weak' : 'Strong'}</div> */}
        </div>
    )
}

export default PasswordStrengthBar