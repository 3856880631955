import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSignup } from '../hooks/useSignup';
import InputField from '../components/authentication/InputField';
// icons
import { MdOutlineEmail, MdOutlineLock } from "react-icons/md";
import { FaRegBuilding } from "react-icons/fa";
import { HiOutlineDocumentChartBar } from "react-icons/hi2";
import { BsFileEarmarkMedical } from "react-icons/bs";
import { IoMdArrowRoundBack } from "react-icons/io";

// components
import PasswordStrengthBar from '../components/authentication/PasswordStrengthBar';
import GoToButton from '../components/shared/GoToButton';
import SelectBox from '../components/new-scan/utility/SelectBox';


const ResearcherSignup = () => {

  // state to track step. Step 1 is for user details and step 2 is for survey
  const [step, setStep] = useState(1);

  // states for the input fields
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [institution, setInstitution] = useState('');

  // can either be research, clinical, or both
  const [intendedUse, setIntendedUse] = useState([]);

  // error states for the input fields (0 = untouched, 1 = valid, 2 = invalid)
  const [emailError, setEmailError] = useState(0);
  const [passwordError, setPasswordError] = useState(0);
  const [confirmPasswordError, setConfirmPasswordError] = useState(0);
  const [firstNameError, setFirstNameError] = useState(0);
  const [lastNameError, setLastNameError] = useState(0);
  const [institutionError, setInstitutionError] = useState(0);
  const [tosError, setTosError] = useState(0);
  const [useCaseError, setUseCaseError] = useState(0);

  // completion states for input fields with requirements
  const [passwordComplete, setPasswordComplete] = useState(false);

  const { signup, error, isLoading, success } = useSignup();

  const handleSignup = async () => {
    if (intendedUse.length === 0) {
      setUseCaseError(2);
      return;
    }
    await signup(email, password, firstName, lastName, institution, intendedUse);
  }

  const handleAddUseType = (type) => {
    if (intendedUse.includes(type)) {
      setIntendedUse(intendedUse.filter(useType => useType !== type));
    } else {
      setIntendedUse([...intendedUse, type]);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasError = false; // Local variable to track if there's any error

    // Check and set error states as needed
    if (emailError !== 1) { setEmailError(2); hasError = true; }
    if (passwordError !== 1) { setPasswordError(2); hasError = true; }
    if (confirmPasswordError !== 1) { setConfirmPasswordError(2); hasError = true; }
    if (firstNameError !== 1) { setFirstNameError(2); hasError = true; }
    if (lastNameError !== 1) { setLastNameError(2); hasError = true; }
    if (institutionError !== 1) { setInstitutionError(2); hasError = true; }
    if (tosError !== 1) { setTosError(2); hasError = true; }

    // Now, use the local variable to decide whether to proceed
    if (hasError) {
      return;
    }

    // If no errors, proceed with signup
    // await signup(email, password, firstName, lastName, institution);
    setStep(2);
  }

  // Input field handlers. Properly checks for errors and sets the error state accordingly
  const firstNameHandler = (value) => {
    setFirstName(value);
    if (value.length > 0) {
      setFirstNameError(1);
    } else {
      setFirstNameError(2);
    }
  }
  const lastNameHandler = (value) => {
    setLastName(value);
    if (value.length > 0) {
      setLastNameError(1);
    } else {
      setLastNameError(2);
    }
  }
  const institutionHandler = (value) => {
    setInstitution(value);
    if (value.length > 0) {
      setInstitutionError(1);
    } else {
      setInstitutionError(2);
    }
  }
  const confirmPasswordHandler = (value) => {
    setConfirmPassword(value);
    if (password !== value) {
      setConfirmPasswordError(2);
    } else {
      setConfirmPasswordError(1);
    }
  }
  const passwordHandler = (value) => {
    setPassword(value);
    if (confirmPasswordError === 0)
      return;

    if (confirmPassword !== value) {
      setConfirmPasswordError(2);
    } else {
      setConfirmPasswordError(1);
    }
  }
  const passwordBlurHandler = (value) => {
    passwordComplete ? setPasswordError(1) : setPasswordError(2);
  }

  /**
   * Confirm that the email entered is valid
   */
  const confirmValidEmail = (value) => {
    // custom regex for email validation
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    setEmailError(re.test(value) ? 1 : 2);
  }

  return (
    <div className='flex text-text-primary'>

      <div className={`relative w-full px-10 md:px-20 py-10 md:w-3/5 flex flex-col items-center justify-center h-screen transition-all duration-500 ${success ? 'pb-0 gap-10' : 'gap-0 pt-1'}`}>
        <div className="flex flex-col pb-5 gap-3">
          <div className="flex justify-center w-full">
            <Link to="/" className='w-2/3 md:w-2/5'>
              <img src={`${process.env.PUBLIC_URL}/logo/normative-dark.svg`} alt="logo" />
            </Link>
          </div>
          {!success && step == 1 && <div className='py-2 w-full'>
            <h1 className="text-3xl font-bold">Sign Up</h1>
            <p className='text-text-secondary-reg text-sm'>New users will be able to log in after an admin approval. Thanks for joining Normative!</p>
          </div>}
        </div>


        {!success &&
          (step === 1
          ?
          <div className='w-full flex flex-col items-center gap-2'>
            <form className="flex flex-col space-y-2 w-full" onSubmit={handleSubmit}>
              <div className='flex flex-col'>
                <div className='flex w-full'>
                  <div className='w-1/2 pr-2'>
                    <InputField name={"First Name"} type={"text"} placeholder={"First Name"} value={firstName} setValue={firstNameHandler} autocomplete={'given-name'} error={firstNameError} />
                  </div>
                  <div className='w-1/2 pl-2'>
                    <InputField name={"Last Name"} type={"text"} placeholder={"Last Name"} value={lastName} setValue={lastNameHandler} autocomplete={'family-name'} error={lastNameError} />
                  </div>
                </div>
              </div>
              <InputField name={"Email"} icon={<MdOutlineEmail />} type={"email"} placeholder={"Email"} value={email} setValue={setEmail} autocomplete={"email"} blurHandler={confirmValidEmail} error={emailError} />
              <InputField name={"Password"} icon={<MdOutlineLock />} type={"password"} placeholder={"Password"} value={password} setValue={passwordHandler} autocomplete={"new-password"} blurHandler={passwordBlurHandler} subComponent={<PasswordStrengthBar password={password} setComplete={setPasswordComplete} />} error={passwordError} />
              <InputField name={"Confirm Password"} icon={<MdOutlineLock />} type={"password"} placeholder={"Confirm Password"} value={confirmPassword} setValue={confirmPasswordHandler} autocomplete={"new-password"} subComponent={(confirmPasswordError === 2 ? <span className='text-red-600 text-xs'>Passwords do not match</span> : confirmPasswordError === 1 ? <span className='text-green-600 text-xs'>Passwords match</span> : <></>)} error={confirmPasswordError} />
              <InputField name={"Institution"} icon={<FaRegBuilding />} type={"text"} placeholder={"Institution"} value={institution} setValue={institutionHandler} autocomplete={null} error={institutionError} />
              <div className='flex gap-2 items-center'>
                <input type="checkbox" className={`${tosError === 2 && ' ring-1 ring-red-500 rounded-xl animate-shake'}`} id="terms" name="terms" value="terms" onChange={(e) => setTosError(e.target.checked ? 1 : 2)} />
                <label htmlFor="terms" className="text-text-secondary-reg text-sm md:text-base py-1">I agree to the <Link to="/terms-of-service" className="text-normative-blue">Terms of Service</Link> and receive account-related emails.</label>
                {/*  and <Link to="/privacy" className="text-normative-blue">Privacy Policy</Link> <span className='text-red-500'>*</span> */}
              </div>
              <button disabled={isLoading} className="bg-normative-blue hover:bg-indigo-600 transition-all text-white py-2 px-5 rounded-lg font-medium active:scale-95 focus:outline-none focus:ring-2 focus:ring-indigo-400">Continue</button>
              {error && <p className='text-red-500'>{error}</p>}
              {/* <Link to="/terms" className="text-sm text-text-secondary-dark">Forgot your password?</Link> */}
            </form>
            <div className="flex flex-col items-center">
              <div className='text-text-secondary-reg'>Already have an account? <Link to="/researcher-login" className='text-normative-blue'>Log in</Link></div>
              {/* <Link to="/researcher-login" className="bg-white text-center border-gray-300 border text-text-secondary-dark w-2/3 py-2 rounded-lg font-medium focus:outline-none focus:ring-2 focus:ring-indigo-400">Log in</Link> */}
            </div>
          </div>
          :
          <div className='w-full flex flex-col gap-5'>
            <div className="flex flex-col">
              <h2 className='text-2xl font-bold'>
                What will you use Normative for? <span className='text-red-500'>*</span>
              </h2>
              {/* Must select at least one */}
              <p className={`text-sm ${useCaseError === 2 ? 'text-red-500' : 'text-text-secondary-light'} transition-colors duration-300`}>Please select at least one</p>
            </div>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-8 w-full'>
              <SelectBox
                isSelected={intendedUse.includes('research')}
                handleClick={() => handleAddUseType('research')}
                handleDeselect={() => setIntendedUse(intendedUse.filter(type => type !== 'research'))}
                isRequired={false}
              >
                <div className='grid grid-cols-[auto,1fr] gap-2 text-text-primary items-center min-h-16'>
                  <HiOutlineDocumentChartBar className='text-4xl lg:text-5xl text-normative-blue' />
                  <div className='flex flex-col text-text-primary'>
                    <h3 className='font-medium'>Research</h3>
                    <p className='text-sm text-text-secondary-reg'>For getting context to study participants</p>
                  </div>
                </div>
              </SelectBox>
              <SelectBox
                isSelected={intendedUse.includes('clinical')}
                handleClick={() => handleAddUseType('clinical')}
                handleDeselect={() => setIntendedUse(intendedUse.filter(type => type !== 'clinical'))}
                isRequired={false}
              >
                <div className='grid grid-cols-[auto,1fr] gap-2 text-text-primary items-center min-h-16'>
                  <BsFileEarmarkMedical className='text-4xl lg:text-5xl text-normative-purple' />
                  <div className='flex flex-col text-text-primary'>
                    <h3 className='font-medium'>Clinical</h3>
                    <p className='text-sm text-text-secondary-reg'>For clinical interpretation and/or diagnosis of patients.</p>
                  </div>
                </div>
              </SelectBox>
            </div>
            <div className='grid grid-cols-[auto,1fr] gap-2'>
              <button onClick={() => { setStep(1); setTosError(0); setUseCaseError(0) }} className="bg-gray-200 hover:bg-gray-300 transition-all text-text-secondary-dark py-2 px-5 rounded-lg font-medium active:scale-95 focus:outline-none focus:ring-2 focus:ring-indigo-400">
                <IoMdArrowRoundBack className='text-xl text-text-secondary-dark' />
              </button>
              <button disabled={isLoading} onClick={handleSignup} className="bg-normative-blue hover:bg-indigo-600 transition-all text-white py-2 px-5 rounded-lg font-medium active:scale-95 focus:outline-none focus:ring-2 focus:ring-indigo-400">Request Account</button>
            </div>
            {error && <p className='text-red-500'>{error}</p>}
          </div>)
        }

        {
          success &&
          <div className='flex flex-col items-center gap-5'>
            <h2 className='text-2xl font-bold'>Sign Up Successful</h2>
            <p className='text-text-secondary-reg'>Your account is pending approval by an admin. You will be notified through E-mail when your account is approved. Thanks for joining Normative!</p>
            {/* Back to home button */}
            <GoToButton to='/' text='Back to Home' reversed={true} />
          </div>
        }

      </div>


      {/* Hidden on mobile */}
      <div className="hidden w-2/5 h-screen md:flex items-center">
        <img src={`${process.env.PUBLIC_URL}/images/doctors.svg`} alt="researcher login" className="" />
      </div>
    </div>
  )
}

export default ResearcherSignup;