import React from 'react'
import Navbar from '../../components/shared/Navbar'
import Footer from '../../components/shared/Footer'

const HomepageTemplate = ({ children }) => {
    return (
        <div className='flex flex-col min-h-screen text-text-primary'>
            <Navbar />
            <div className="flex-grow flex flex-col h-full">
                {children}
            </div>
            <Footer />
        </div>
    )
}

export default HomepageTemplate