import { lazy } from 'react';
// import UpatListConverterPage from '../pages/UpatListConverterPage';
import ResearcherSignup from '../pages/ResearcherSignup';
import { Navigate } from 'react-router-dom';

const Home = lazy(() => import("../pages/Home"));
const Plans = lazy(() => import("../pages/Plans"));
const Faq = lazy(() => import("../pages/Faq"));
const Admin = lazy(() => import("../pages/Admin"));
const About = lazy(() => import("../pages/About"));
const Documentation = lazy(() => import("../pages/Documentation"));
const Features = lazy(() => import("../pages/Features"));
const PatientLogin = lazy(() => import("../pages/PatientLogin"));
const ResearcherLogin = lazy(() => import("../pages/ResearcherLogin"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Report = lazy(() => import("../pages/Report"));
const ReportBuilder = lazy(() => import("../pages/ReportBuilder"));
const NotFound = lazy(() => import("../pages/NotFound"));
const Patients = lazy(() => import("../pages/Patients"));
const ReportViewer = lazy(() => import("../pages/ReportViewer"));
const Settings = lazy(() => import("../pages/Settings"));
const Terms = lazy(() => import("../pages/Terms"));

// admin sections
const AdminOverview = lazy(() => import("../pages/AdminOverview"));
const AdminUsers = lazy(() => import("../pages/AdminUsers"));
const AdminReports = lazy(() => import("../pages/AdminReports"));
const AdminSettings = lazy(() => import("../pages/AdminSettings"));
const AdminEmail = lazy(() => import("../pages/AdminEmail"));
const AdminTesting = lazy(() => import("../pages/AdminTesting"));
const AdminDatasets = lazy(() => import("../pages/AdminDatasets"));
const AdminDatasetView = lazy(() => import("../pages/AdminDatasetView"));
const AdminUserEditView = lazy(() => import("../pages/AdminUserEditView"));

// Contains all public pages accessible in the page
export const publicRoutes = [
    {
        path: '/',
        element: <Home />,
    },
    {
        path: '/features',
        element: <Features />,
    },
    // {
    //     path: '/plans',
    //     element: <Plans />,
    // },
    {
        path: '/faq',
        element: <Faq />,
    },
    {
        path: '/documentation',
        element: <Documentation />,
    },
    {
        path: '/about',
        element: <About />,
    },
    {
        path: '/terms-of-service',
        element: <Terms />,
    },
    {
        path: '*',
        element: <NotFound />,
    },


];

export const privateRoutes = [
    {
        path: '/patients',
        element: <Patients />,
    },
    {
        path: '/reports/:reportId',
        element: <ReportViewer />,
    },
    {
        path: '/dashboard',
        element: <Dashboard />,
    },
    {
        path: '/reports',
        element: <Report />,
    },
    {
        path: '/new-report',
        element: <ReportBuilder />,
    },
    // {
    //     path: '/upatlist-convert',
    //     element: <UpatListConverterPage />,
    // },
    {
        path: '/settings/:view',
        element: <Settings />,
    },
];

export const authRoutes = [
    {
        path: '/patient-login',
        element: <PatientLogin />,
    },
    {
        path: '/researcher-login',
        element: <ResearcherLogin />,
    },
    {
        path: '/researcher-signup',
        element: <ResearcherSignup />,
    }
];

export const adminRoutes = [
    {
        path: '/admin',
        element: <Navigate to="/admin/overview" replace />,
    },
    {
        path: '/admin/overview',
        element: <AdminOverview />,
    },
    {
        path: '/admin/users',
        element: <AdminUsers />,
    },
    {
        path: '/admin/users/:userId',
        element: <AdminUserEditView />,
    },
    {
        path: '/admin/reports',
        element: <AdminReports />,
    },
    {
        path: '/admin/datasets',
        element: <AdminDatasets />,
    },
    {
        path: '/admin/settings',
        element: <AdminSettings />,
    },
    {
        path: '/admin/email',
        element: <AdminEmail />,
    },
    {
        path: '/admin/debug',
        element: <AdminTesting />,
    },
    {
        path: '/admin/datasets/:datasetId',
        element: <AdminDatasetView />,
    }
];
