export const getReports = async (userId, sortField, sortDirection, reportType='all') => {
    return await fetch(`/api/report/${userId}?sortField=${sortField}&sortDirection=${sortDirection}`, {
        credentials: 'include',
    });
}

export const createPdf = async (report, single) => {
    try {
        const response = await fetch(`/api/report/pdf/${report._id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ single }),
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        const fileName = single ? `${report.reportPatients[single.patientId].name} - ${report.reportType.toUpperCase()} Report.pdf` : `${report.reportName}.pdf`;
        return { blob, fileName };
    } catch (error) {
        console.error('PDF Download error:', error);
        throw error; // Rethrow to allow caller to handle the error
    }
}

export const updateReport = async (accountId, report) => {
    return await fetch('/api/report/recalculate', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ accountId, report }),
        credentials: 'include',
    });
}

export const deleteManyReports = async (reportIds) => {
    return await fetch('/api/report/delete-many', {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ reportIds }),
        credentials: 'include',
    });
}