import React, { useState, useRef, useEffect } from 'react'
import { RiArrowDropDownLine } from "react-icons/ri";
import { IoSettingsOutline, IoLogOutOutline } from 'react-icons/io5';
import { useUser } from '../../contexts/UserContext';
import { GrUserAdmin } from "react-icons/gr";
import { Link } from 'react-router-dom';

const AccountBubble = () => {
  const { state, dispatch } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const dropboxRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropboxRef.current && !dropboxRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [])

  if (!state.user) 
    return null;
  return (
    <div ref={dropboxRef} className='flex flex-col items-center justify-center h-full relative z-30'>
      {/* <div className="py-2.5 px-3 rounded-full bg-normative-purple border-2 ring ring-normative-blue">KA</div> */}
      {/* <img src={`${process.env.PUBLIC_URL}/images/profile_pic.svg`} alt="Profile" className="rounded-full hover:bg-zinc-300 bg-normative-cream border-2 ring ring-normative-blue max-w-12" /> */}
      <button
        onClick={() => setIsOpen(prev => !prev)}
        className="flex items-center hover:cursor-pointer px-2 py-1.5 rounded-md transition-all group
             hover:bg-zinc-500/20"
      >
        <div className="flex flex-col">
          <span className='font-medium'>{state.user ? state.user.firstName : 'Log in'}</span>
          {/* <span className='text-sm text-text-secondary-reg'>{state.user.email}</span> */}
        </div>
        <RiArrowDropDownLine className={`text-3xl transition-all ${isOpen ? '-rotate-180' : ''}`} />
      </button>
      <div className={`absolute overflow-hidden flex flex-col gap-1 top-full justify-center right-0 text-text-primary bg-white w-52 shadow-md ${isOpen ? state.user.role === 'admin' ? 'h-40 border-x border-b' : 'h-28 border-x border-b' : 'h-0'} transition-all px-5`}>
        {state.user.role === 'admin' &&
          <Link to='/admin' className={`${isOpen ? 'block' : 'hidden'} px-1 w-full py-2.5 hover:bg-indigo-100 rounded-md transition-all flex items-center gap-3`}>
            <GrUserAdmin className='text-normative-blue' />
            <span>Admin Panel</span>
          </Link>}
        <Link to='/settings/account-details' className={`${isOpen ? 'block' : 'hidden'} px-1 w-full py-2.5 hover:bg-zinc-200 rounded-md transition-all flex items-center gap-3`}>
          <IoSettingsOutline />
          <span>Settings</span>
        </Link>
        <hr />
        <button onClick={() => { dispatch({ type: 'LOGOUT' }) }} className={`${isOpen ? 'block' : 'hidden'} w-full p-2.5 text-red-600 hover:bg-red-100 rounded-md transition-all text-left gap-3 flex items-center`}>
          <IoLogOutOutline />
          <span>Logout</span>
        </button>
      </div>
      {/* <button onClick={() => { dispatch({ type: 'LOGOUT' }) }}>Logout</button> */}
    </div>
  )
}

export default AccountBubble