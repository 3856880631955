import React from 'react';
import { MdOutlineClose, MdOutlineErrorOutline, MdOutlineCheckCircle } from "react-icons/md";
import { HiOutlineInformationCircle } from "react-icons/hi2";


const SmallPopupTop = ({ message, isShowing, setShow, type, title }) => {
    const borderColour = type === 'error' ? 'border-red-500' : type === 'success' ? 'border-green-500' : type === 'info' ? 'border-blue-500' : type === 'warning' ? 'border-yellow-500' : null;
    // const backgroundColour = type === 'error' ? 'bg-red-50' : type === 'success' ? 'bg-green-50' : type === 'info' ? 'bg-blue-50' : type === 'warning' ? 'bg-yellow-50' : 'bg-white';
    return (

        <div className={`text-text-secondary-dark bg-white shadow-sm fixed ${isShowing ? 'mt-0' : '-mt-96'} top-6 transition-all flex gap-5 items-center justify-between px-3 py-2 max-w-96 min-w-72 min-h-20 right-0 mr-3 rounded-md border z-40 ${borderColour && borderColour + ' border-l-8'}`}>
            <div className="w-min">
                {type === 'error' && <MdOutlineErrorOutline className='text-red-500 text-3xl' />}
                {type === 'success' && <MdOutlineCheckCircle className='text-green-500 text-3xl' />}
                {type === 'info' && <HiOutlineInformationCircle className='text-blue-500 text-3xl' />}
                {type === 'warning' && <HiOutlineInformationCircle className='text-yellow-500 text-3xl' />}
            </div>
            <div className="flex flex-col flex-grow">
                {title && <h1 className='font-semibold'>{title}</h1>}
                <p className='text-xs'>{message}</p>
            </div>
            <div className="w-min">
                <button onClick={() => setShow(false)} className='hover:bg-slate-100 p-0.5 rounded-full h-min'><MdOutlineClose className='text-2xl text-text-secondary-reg'/></button>
            </div>
        </div>
    );
};

export default SmallPopupTop;