import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { publicRoutes, privateRoutes, authRoutes, adminRoutes } from './utils/routes';
import { Suspense, useState, useEffect } from 'react';
import { useUser } from './contexts/UserContext';
import LoadingScreen from './components/shared/LoadingScreen';
import { useCookies } from 'react-cookie';
import { getUser } from './utils/api/userAPI';
import NotFound from './pages/NotFound';


function App() {
  // TODO: Should not need to check if cookies exist. fix this
  const [cookies, removeCookie] = useCookies(['jwt']);
  const [loading, setLoading] = useState(true);
  const [loggedOut, setLoggedOut] = useState(false);
  const { state, dispatch } = useUser();

  const needLogIn = () => {
    const hasCookie = (cookies.jwt || cookies !== 'undefined') ? true : false;
    return hasCookie && !state.user;
  }

  // if (cookies.jwt && !state.user) {
  //   return <LoadingScreen />;
  // }

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (cookies.jwt) {
          const res = await getUser();
          const data = await res.json();

          if (res.ok) {
            dispatch({ type: 'LOGIN', payload: data.user });
          } else {
            throw new Error('Error fetching user data', res.status, data.message);
          }
        } else {
          setLoggedOut(true);
        }

      } catch (error) {
        setLoggedOut(true);
        console.error(error);
        // TODO: Show error message
        dispatch({ type: 'LOGOUT_CONFIRM', payload: { message: 'Error fetching user. Please log in again.', removeCookie } });
        // removeCookie('jwt', { path: '/' });
      }
      setLoading(false);
    }

    if (needLogIn() && !loggedOut) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [cookies.jwt, dispatch, removeCookie, loggedOut]);



  return (
    <>
      {
        loading && (cookies.jwt && !state.user) ? <LoadingScreen /> :
          <Router>
            <Suspense fallback={<></>}>
              <Routes>
                {publicRoutes.map((route) => (
                  <Route key={route.path} path={route.path} element={route.element} />
                ))}
                {privateRoutes.map((route) => (
                  <Route key={route.path} path={route.path} element={(cookies.jwt && state.user) ? route.element : <Navigate to="/" />} />
                ))}
                {authRoutes.map((route) => (
                  <Route key={route.path} path={route.path} element={(cookies.jwt && state.user) ? <Navigate to="/dashboard" /> : route.element} />
                ))}
                {adminRoutes.map((route) => (
                  <Route key={route.path} path={route.path} element={(cookies.jwt && state.user && state.user.role === 'admin') ? route.element : <NotFound />} />
                ))}
              </Routes>
            </Suspense>
          </Router>
      }
    </>

  );
}

export default App;
