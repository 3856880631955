
export const updateUserDetails = async (firstName, lastName, institution) => {
    return await fetch('/api/user/update', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ firstName, lastName, institution }),
        credentials: 'include',
    });
}

export const getUser = async () => {
    return await fetch('/api/user', {
        method: 'GET',
        credentials: 'include',
    });
}

export const updatePassword = async (currentPassword, newPassword) => {
    return await fetch('/api/user/change-password', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ currentPassword, newPassword }),
        credentials: 'include',
    });
}

export const updateEmail = async (email, password) => {
    return await fetch('/api/user/change-email', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
        credentials: 'include',
    });
}

export const logoutUser = async () => {
    return await fetch('/api/user/logout', {
        method: 'GET',
        credentials: 'include',
    });
}

export const deleteUser = async (password) => {
    return await fetch('/api/user/delete', {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password }),
        credentials: 'include',
    });
}