import React from 'react';

const ConfirmOverlayModal = ({ message, subMessage, isOpen, onCancel, onConfirm, children }) => {
    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    return (
        <>
            {isOpen && (
                <div className="w-full p-5 h-screen bg-black bg-opacity-50 fixed inset-0 flex justify-center items-center hover:cursor-pointer z-50" onClick={onCancel}>
                    <div className="flex flex-col bg-white rounded-md gap-2 p-3 hover:cursor-default" onClick={stopPropagation}>
                        <h2 className='font-semibold text-lg text-text-primary'>{message}</h2>
                        <hr className='w-10/12' />
                        {subMessage && <p className='text-text-secondary-dark'>{subMessage}</p>}
                        {children}
                        <div className="flex gap-3 justify-end mt-10">
                            <button className='px-2 py-0.5 rounded-md bg-slate-100' onClick={onCancel}>Cancel</button>
                            <button className='px-2 py-0.5 rounded-md bg-normative-blue text-white' onClick={onConfirm}>Confirm</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ConfirmOverlayModal;