import { useState } from "react";

export const useSignup = () => {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const signup = async (email, password, firstName, lastName, institution, intendedUse = []) => {
        setIsLoading(true);
        setError(null);

        try {
            const response = await fetch('/api/user/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password, firstName, lastName, institution, intendedUse }),
                credentials: 'include',
            });

            const json = await response.json();
            if (!response.ok) {
                setError(json.message);
                setIsLoading(false);
                return;
            }

            if (response.ok) {
                setSuccess(true);
                // dispatch({ type: 'LOGIN', payload: json.user });
                setIsLoading(false);
            }

        } catch (error) {
            setError('Something went wrong. Please try again later.');
            setIsLoading(false);
        }


    }

    return { signup, error, isLoading, success };

}
