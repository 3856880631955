import React, { useState, useEffect } from 'react'

const Footer = () => {

    const [lastUpdated, setLastUpdated] = useState('');
    const [sinceLastUpdated, setSinceLastUpdated] = useState('');
    const [version, setVersion] = useState('');

    useEffect(() => {
        const fetchLastCommitDate = async () => {
            try {
                const response = await fetch('https://api.github.com/repos/Bonelab/normative-2.0-webapp/commits/production', {
                    headers: {
                        'Authorization': `token ${process.env.REACT_APP_GITHUB_TOKEN}`
                    }
                }).then(res => res.json());
                const lastCommitDate = new Date(response.commit.committer.date);
                const commitSHA = response.sha.substring(0, 7);

                const formattedDate = new Intl.DateTimeFormat('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true
                }).format(lastCommitDate);

                setLastUpdated(formattedDate);

                // Calculate the time since the last commit
                const now = new Date();
                const diffTime = Math.abs(now - lastCommitDate);
                const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
                const diffHours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const diffMinutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
                const diffSeconds = Math.floor((diffTime % (1000 * 60)) / 1000);

                if (diffDays > 0) {
                    setSinceLastUpdated(`${diffDays} day${diffDays > 1 ? 's' : ''} ago`);
                } else if (diffHours > 0) {
                    setSinceLastUpdated(`${diffHours} hour${diffHours > 1 ? 's' : ''} ago`);
                } else if (diffMinutes > 0) {
                    setSinceLastUpdated(`${diffMinutes} minute${diffMinutes > 1 ? 's' : ''} ago`);
                } else {
                    setSinceLastUpdated(`${diffSeconds} second${diffSeconds > 1 ? 's' : ''} ago`);
                }

                // Update version in this format v1.(commit number).(commit date in this format mmddyy)
                const commitDateFormatted = new Intl.DateTimeFormat('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: '2-digit'
                }).format(lastCommitDate).replace(/\//g, '');
                const version = `v1.${commitSHA}.${commitDateFormatted}`;
                setVersion(version);

            } catch (error) {
                console.error('Error fetching the last commit date:', error);
            }
        };

        fetchLastCommitDate();
    }, []);
    return (
        <div className='bg-normative-dark-panel'>
            {/* Beta disclaimer. TODO: Remove on production */}
            <div className='py-10 bg-normative-black text-white text-center'>
                <p className='text-center text-red-600'>This is a beta version of the Normative platform. Please report any issues to <a href='mailto:skboyd@ucalgary.ca' className='text-normative-blue hover:underline'>skboyd@ucalgary.ca</a> or <a href='https://bonelab.ca/' className='text-normative-blue hover:underline'>visit our website</a> for more information.</p>
                <p>Normative is currently undergoing a private testing. Some features may be missing or not working. Thank you for your patience.</p>
                <div className='pt-2'>Last updated: {lastUpdated} <span className='text-sm text-gray-400'>{`${sinceLastUpdated}`}</span></div>
                <div className='text-sm'><span className='text-sm text-gray-400'>{`${version}`}</span></div>
            </div>
            <div className='flex justify-center py-10'>
                <p className='text-white'>© 2024 Normative. All rights reserved.</p>
            </div>
        </div>
    )
}

export default Footer