import React from 'react'
import { Link } from 'react-router-dom'

const GoToButton = ({ to, text, width, height, reversed }) => {
    // Button Design from https://devdojo.com/tailwindcss/buttons
    return (
        <Link to={to} className={`w-${width} ${reversed && 'rotate-180'} relative inline-flex items-center justify-start py-${height ? height : 3} pl-4 pr-12 overflow-hidden font-semibold text-white transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-normative-blue group`}>
            <span className="absolute bottom-0 left-0 w-full h-0 transition-all duration-150 ease-in-out bg-normative-dark-panel group-hover:h-full"></span>
            <span className="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
                <svg className="w-5 h-5 text-normative-dark-panel" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
            </span>
            <span className="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
                <svg className="w-5 h-5 text-normative-blue" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
            </span>
            <span className={`${reversed && 'rotate-180'} relative w-full font-medium text-md text-left transition-colors duration-200 ease-in-out group-hover:text-white`}>{text}</span>
        </Link>
    )
}

export default GoToButton